import "./metronic-ui-kit/assets/plugins/global/plugins.bundle.css";
import "./metronic-ui-kit/assets/css/style.bundle.css";

import { createApp } from 'vue'
import { initializeApp } from "firebase/app";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

const firebaseConfig = {
  apiKey: "AIzaSyDFH7nrkx0DkCwaqiT9lEX4__Z1Ue-cptQ",
  authDomain: "entry-sentry.firebaseapp.com",
  projectId: "entry-sentry",
  storageBucket: "entry-sentry.appspot.com",
  messagingSenderId: "499698022006",
  appId: "1:499698022006:web:31880c57627a5ea7cb9157",
  measurementId: "G-8K9BVYJZTK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

createApp(App).use(router).mount('#app')
