<template>
  <TrackVisibility />
  
  <div class="es_property_listing" :class="{'es_loading': !propertyDetails || loadingData}">
    <section class="es_loading_hero" :class="{'es_hide': propertyDetails && !loadingData && !showWelcomeMessage}">
      <img src="../assets/logo-white-big.png" class="es_logo" />
      
      <h2 class="es_h2" v-if="!propertyDetails || loadingData">Creating a secure<br/>Open House</h2>
      <h2 class="es_h2" v-if="propertyDetails && !loadingData">Welcome to the<br/>Open House</h2>
            
      <h3 class="es_h3" v-if="propertyDetails && !loadingData">📍&nbsp;{{getStreetName()}}, {{propertyDetails.address.locality}}</h3>
      
      <img src="../assets/unlocked.png" v-if="showWelcomeMessage" class="es_unlocked" />
          
      <h3 class="es_h3 es_unlocked_message" v-if="showWelcomeMessage">
        You can enter the property now
      </h3>
      
      <div class="es_loader" :class="{'es_hide': !loadingData}">
        <Spinner message="Loading data..." />
      </div>
      
      <div class="es_error" v-show="errorMessage" :class="{'es_active': errorMessage}">
        <span v-if="errorMessage"><b class="es_b">⚠️</b><br/>{{errorMessage}}</span>
      </div>

      <div class="es_access_code_panel es_active" v-if="showWelcomeMessage && showAccessCode && propertyDetails.accessCode">
        <h3 class="es_h3">CBS Access Code</h3>
        <span class="es_access_code">{{propertyDetails.accessCode}}</span>
      </div>
    </section>
    
    <section id="es_signin_form" v-if="propertyDetails && !loadingData && !showWelcomeMessage">
      <div id="es_signin_form_header" v-if="activeFormStep > 0">
        <h3 class="es_h3">📍&nbsp;{{getStreetName()}}, {{propertyDetails.address.locality}}</h3>
      </div>
      
      <div id="es_signin_form_inner" v-bind:class="'active_step_' + activeFormStep">
        <div class="es_signin_form_section">
          <div class="es_signin_form_section_content es_welcome">
            <img src="../assets/logo-big.png" class="es_logo"  />
            
            <h2 class="es_h2" >Welcome to the<br/>Open House<br/><br/>📍&nbsp;{{getStreetName()}}, {{propertyDetails.address.locality}}</h2>
            <h4 class="es_h4">Please fill in the fields below.<br/><b>It only takes 2 minutes.</b><br/><br/>We do not sell or use your personal information for any other purposes.<br/><br/>Click the "Start" button below to begin.</h4>
            
            <a class="es_btn" @click="activeFormStep = 1; activateInput('#name');">Start</a>
          </div>
        </div>
        <div class="es_signin_form_section">
          <div class="es_signin_form_section_content">
            <h2 class="es_h2">
              [1 of {{getTotalFormSteps()}}] Full name*
            </h2>
            <h4 class="es_h4">
              Please provide your first and last name:
            </h4>
            
            <input type="text" id="name" name="name" class="es_input" @keyup.enter="validateProperty('fullname', '#email')" v-model="visitor.fullname" placeholder="Type your name here..." />
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['fullname']">{{invalidFields['fullname']}}</h4>
            
            <a class="es_btn" @click="validateProperty('fullname', '#email')">Next →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [2 of {{getTotalFormSteps()}}] Email address*
            </h2>
            <h4 class="es_h4">
              Your email address will only be used for sending you this agreement and property information.
            </h4>
            
            <input type="email" id="email" name="email" class="es_input" @keyup.enter="validateProperty('email', '#phone')" v-model="visitor.email" placeholder="Type your email here..." />
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['email']">{{invalidFields['email']}}</h4>
            
            <a class="es_btn" @click="validateProperty('email', '#phone')">Next →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [3 of {{getTotalFormSteps()}}] Phone number
            </h2>
            <h4 class="es_h4">
              Please provide your phone number:
            </h4>
            
            <input type="phone" id="phone" name="phone" class="es_input" @keyup.enter="validateProperty('phone')" v-model="visitor.phone" placeholder="Type your phone here..." />
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['phone']">{{invalidFields['phone']}}</h4>
            
            <a class="es_btn" @click="validateProperty('phone')" v-if="visitor.phone">Next →</a>
            <a class="es_btn" @click="validateProperty('phone')" v-if="!visitor.phone">Skip →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [4 of {{getTotalFormSteps()}}] Visit Type*
            </h2>
            <h4 class="es_h4">
              What type of visit?<br/>
              Not a buyer or agent? Select "Other".
            </h4>
            
            <div class="es_btn_group">
              <a class="es_btn" :class="{'es_active': visitor.visitType === 'buyer'}" @click="visitor.visitType = 'buyer'" >I'm a Buyer <img src="../assets/active_icon.png" class="active-icon" /> <img src="../assets/inactive_icon.png" class="inactive-icon" /></a>
              <a class="es_btn" :class="{'es_active': visitor.visitType === 'agent'}" @click="visitor.visitType = 'agent'">I'm an Agent <img src="../assets/active_icon.png" class="active-icon" /> <img src="../assets/inactive_icon.png" class="inactive-icon" /></a>
              <a class="es_btn" :class="{'es_active': visitor.visitType === 'other'}" @click="visitor.visitType = 'other'">Other <img src="../assets/active_icon.png" class="active-icon" /> <img src="../assets/inactive_icon.png" class="inactive-icon" /></a>
            </div>
            
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['visitType']">{{invalidFields['visitType']}}</h4>
            
            <a class="es_btn" v-if="!visitor.visitType || visitor.visitType === 'buyer'" @click="validateProperty('visitType', '#representingAgent')">Next →</a>
            <a class="es_btn" v-if="visitor.visitType === 'agent'" @click="validateProperty('visitType', '#brokerageName')">Next →</a>
            <a class="es_btn" v-if="visitor.visitType === 'other'" @click="validateProperty('visitType', '#typeOther')">Next →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section" v-if="visitor.visitType === 'buyer'">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [5 of {{getTotalFormSteps()}}] Do you have your own agent?
            </h2>
            <h4 class="es_h4">
              Please provide the representing agent name:<br/>
            </h4>
            
            <input type="text" id="representingAgent" name="representingAgent" @keyup.enter="validateProperty('representingAgent', '#postalCode')" class="es_input" v-model="visitor.representingAgent" placeholder="Type agent name here..." />
            <h4 class="es_h4 es_optional_note">This field is optional.</h4>
            
            <a class="es_btn" @click="validateProperty('representingAgent', '#postalCode')" v-if="visitor.representingAgent">Next →</a>
            <a class="es_btn" @click="validateProperty('representingAgent', '#postalCode')" v-if="!visitor.representingAgent">Skip →</a>
            
          </div>
        </div>
        
        <div class="es_signin_form_section" v-if="visitor.visitType === 'other'">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [5 of {{getTotalFormSteps()}}] Visit Purpose*
            </h2>
            <h4 class="es_h4">
              Please provide the purpose of the visit:<br/>
            </h4>
            
            <input type="text" id="typeOther" name="typeOther" @keyup.enter="validateProperty('typeOther', '#postalCode')" class="es_input" v-model="visitor.visitTypeOther" placeholder="Type visit purpose here..." />
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['typeOther']">{{invalidFields['typeOther']}}</h4>
            
            <a class="es_btn" @click="validateProperty('typeOther', '#postalCode')">Next →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section" v-if="visitor.visitType === 'agent'">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [5 of {{getTotalFormSteps()}}] Brokerage Name*
            </h2>
            <h4 class="es_h4">
              Please provide the brokerage name:<br/>
            </h4>
            
            <input type="text" id="brokerageName" name="brokerageName" @keyup.enter="validateProperty('brokerageName', '#licenseNumber')" class="es_input" v-model="visitor.brokerageName" placeholder="Type brokerage name here..." />
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['brokerageName']">{{invalidFields['brokerageName']}}</h4>
            
            <a class="es_btn" @click="validateProperty('brokerageName', '#licenseNumber')">Next →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section" v-if="visitor.visitType === 'agent'">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [6 of {{getTotalFormSteps()}}] License Number*
            </h2>
            <h4 class="es_h4">
              Please provide the license number:<br/>
            </h4>
            
            <input type="text" id="licenseNumber" name="licenseNumber" @keyup.enter="validateProperty('licenseNumber', '#numberOfVisitors')" class="es_input" v-model="visitor.licenseNumber" placeholder="Type license number here..." />
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['licenseNumber']">{{invalidFields['licenseNumber']}}</h4>
            
            <a class="es_btn" @click="validateProperty('licenseNumber', '#numberOfVisitors')">Next →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section" v-if="visitor.visitType === 'agent'">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [7 of {{getTotalFormSteps()}}] Number of Visitors*
            </h2>
            <h4 class="es_h4">
              Please provide the number of visitors:<br/>
            </h4>
            
            <input type="text" id="numberOfVisitors" name="numberOfVisitors" @keyup.enter="validateProperty('numberOfVisitors', '#postalCode')" class="es_input" v-model="visitor.numberOfVisitors" placeholder="Type number of visitors here..." />
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['numberOfVisitors']">{{invalidFields['numberOfVisitors']}}</h4>
            
            <a class="es_btn" @click="validateProperty('numberOfVisitors', '#postalCode')">Next →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [{{visitor.visitType === 'agent' ? 8 : 6}} of {{getTotalFormSteps()}}] Zipcode
            </h2>
            <h4 class="es_h4">
              Please provide your zipcode:<br/>
            </h4>
            
            <input type="text" id="postalCode" name="postalCode" class="es_input" @keyup.enter="validateProperty('postalCode')" v-model="visitor.postalCode" placeholder="Type zip code here..." />
            <h4 class="es_h4 es_optional_note">This field is optional</h4>
            
            <a class="es_btn" @click="validateProperty('postalCode')" v-if="visitor.postalCode">Next →</a>
            <a class="es_btn" @click="validateProperty('postalCode')" v-if="!visitor.postalCode">Skip →</a>
            
          </div>
        </div>
        
        <div class="es_signin_form_section" v-if="questionnaire">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [{{visitor.visitType === 'agent' ? 9 : 7}} of {{getTotalFormSteps()}}] {{questionnaire.title}}*
            </h2>
            <h4 class="es_h4 es_small" v-if="questionnaire.preamble">{{questionnaire.preamble}}</h4>
            
            <h4 class="es_h4" style="margin-bottom: -20px;">
              Please accept our <a href="https://www.esentry.net/privacy" target="_blank">Privacy Policy</a><br/> and <a href="https://www.esentry.net/terms" target="_blank">Terms of Service</a>
            </h4>
            
            <div class="es_btn_group">
              <a class="es_btn" :class="{'es_active': visitor.agreeWithTerms}" @click="visitor.agreeWithTerms = true" >I have read and accept the Terms of Service <img src="../assets/active_icon.png" class="active-icon" /> <img src="../assets/inactive_icon.png" class="inactive-icon" /></a>
            </div>
            
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['agreeWithTerms']">Please accept the Privacy Policy and Terms of Service.</h4>
            
            <a class="es_btn" @click="validateProperty('agreeWithTerms')">Next →</a>
          </div>
        </div>      
        
        <div class="es_signin_form_section" v-if="questionnaire && questions && questions.length > 0">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [{{visitor.visitType === 'agent' ? 10 : 8}} of {{getTotalFormSteps()}}] {{questionnaire.title}}
            </h2>
            
            <p class="es_question" v-for="(question, index) in questions">{{question.question}} </p>
            
            <a class="es_btn" @click="submitAnswer(); validateProperty();">I Agree →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section" v-if="questionnaire">
          <div class="es_signin_form_section_content">
            <small class="btn_previous" @click="--activeFormStep">← Back</small>
            <h2 class="es_h2">
              [{{visitor.visitType === 'agent' ? 10 : 8}} of {{getTotalFormSteps()}}] {{questionnaire.title}}*
            </h2>
            <h4 class="es_h4 es_small" v-if="questionnaire.postamble">{{questionnaire.postamble}}</h4>
            
            <section class="es_user_signature es_form_input">
              <h2 class="es_h2">Your signature</h2>
              <span class="es_clear_icon" @click="clearSignature()" v-if="hasSignature()">×</span>
              
              <canvas class="es_canvas" id="user_signature_canvas"></canvas>
            </section>
            
            <h4 class="es_h4 es_invalid_note" v-if="invalidFields['signature']">{{invalidFields['signature']}}</h4>
            
            <a class="es_btn" @click="submitForm();" :class="{'es_disabled': submittingForm}">Enter the house →</a>
          </div>
        </div>
        
        <div class="es_signin_form_section" v-if="questionnaire">
          <div class="es_signin_form_section_content">
            <h2 class="es_h2">
              Creating a secure<br/>Open House
            </h2>
            <h4 class="es_h4 es_small">We are now processing your request...</h4>
            
            <div class="es_loader">
              <Spinner message="Loading data..." />
            </div>
          </div>
        </div>
        
        
      </div>
    </section>
    
  </div>
</template>

<style lang="scss">  
  #es_signin_form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: $white-color;
    z-index: 100;
    
    #es_signin_form_header {
      position: absolute;
      left: 0;
      top: 0;
      background: $black-light-color;
      width: 100%;
      text-align: center;
      padding: 7px 5px;
      z-index: 50;
      
      .es_h3 {
        font-size: 18px;
        color: $white-color;
        margin: 0;
      }
    }
    
    #es_signin_form_inner {
      position: absolute;
      z-index: 30;
      transform: translateY(0);
      width: 100vw;
      transition: transform 450ms ease-in;
      
      &.active_step_0 { transform: translateY(0); }
      &.active_step_1 { transform: translateY(-100vh); }
      &.active_step_2 { transform: translateY(-200vh); }
      &.active_step_3 { transform: translateY(-300vh); }
      &.active_step_4 { transform: translateY(-400vh); }
      &.active_step_5 { transform: translateY(-500vh); }
      &.active_step_6 { transform: translateY(-600vh); }
      &.active_step_7 { transform: translateY(-700vh); }
      &.active_step_8 { transform: translateY(-800vh); }
      &.active_step_9 { transform: translateY(-900vh); }
      &.active_step_10 { transform: translateY(-1000vh); }
      &.active_step_11 { transform: translateY(-1100vh); }
      &.active_step_12 { transform: translateY(-1200vh); }
      &.active_step_13 { transform: translateY(-1300vh); }
      &.active_step_14 { transform: translateY(-1400vh); }
      
      .es_signin_form_section {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .es_signin_form_section_content {
        width: 80vw;
        
        &.es_welcome {
          text-align: center;
          transform: translateY(-10vh);
          
          .es_logo {
            margin-bottom: 90px;
          }
          
          .es_h2 {
            margin-bottom: 30px;
            font-size: 24px;
          }
          
          .es_h4 {
            margin-bottom: 30px;
          }
        }
        
        .btn_previous {
          margin-bottom: 20px;
          display: block;
          font-size: 14px;
          opacity: 0.4;
        }
        
        .es_input {
          display: block;
          width: 100%;
          font-size: 22px;
          margin-top: 45px;
          margin-bottom: 24px;
          border: 0;
          border-bottom: 1px solid $black-light-color;
          padding-bottom: 8px;
          outline: none;
        }
        
        .es_btn_group {
          display: block;
          margin-top: 45px;
          margin-bottom: 24px;
          
          .es_btn {
            display: block;
            text-align: left;
            border-radius: 4px;
            background-color: rgba(92, 92, 92, 0.1);
            box-shadow: rgb(92 92 92 / 60%) 0px 0px 0px 1px inset;
            color: rgb(92, 92, 92);
            margin: 8px 0 !important;
            position: relative;
            text-transform: none;
            height: auto;
            line-height: 1.4em;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-right: 60px;
            
            img {
              position: absolute;
              right: 16px;
              top: 50%;
              transform: translateY(-50%);
              display: none;
              height: 22px;;
              
              &.inactive-icon {
                display: block;
              }
            }
            
            &.es_active {
              box-shadow: rgb(92 92 92 / 80%) 0px 0px 0px 2px inset;
              
              img {
                display: block;
                
                &.inactive-icon {
                  display: none;
                }
              }
            }
          }
        }
        
        .es_logo {
          height: 60px;
        }
        
        .es_question {
          font-weight: 600;
          font-size: 15px;
          line-height: 21px;
          margin-bottom: 24px;
        }
        
        .es_h2 {
          color: $black-light-color;
          font-size: 20px;
          margin-bottom: 16px;
        }
        
        .es_h4 {
          font-size: 15px;
          color: $black-light-color;
          opacity: 0.7;
          font-weight: 400;
          
          &.es_small {
            font-size: 12px;  
          }
        }
        
        .es_invalid_note {
          color: $red-color;
          transform: translateY(-16px);
        }

        .es_optional_note {
          transform: translateY(-16px);
        }
        
        .es_btn {
          display: inline-block;
          height: 50px;
          background-color: $base-color;
          color: $white-color;
          border: 0;
          font-size: 18px;
          border-radius: 2px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          line-height: 52px;
          padding-left: 24px;
          padding-right: 24px;
          
          &.es_btn_outline {
            border: 1px solid rgba(0,0,0,0.2);
            background-color: $white-color;
            border-radius: 2px;
            color: rgba(0, 0, 0, 0.2);
            margin-left: 14px;
            
            &.es_active {
              border: 2px solid $base-color;
              color: $base-color;
            }
          }
          
          &.es_disabled {
            opacity: 0.4;
            cursor: not-allowed;
            background-color: $black-light-color;
          }
        }
      }
    }
  }






  .es_property_listing {
    padding-bottom: 110px;
    height: 100vh;
    overflow: hidden;
    
    &.es_loading {
      padding-bottom: 0;
    }
  }
  
  .es_disclaimer {
    text-align: center;
    font-size: 14px;
    width: 75vw;
    margin: 48px auto 0;
    opacity: 0.5;
  }
  
  .es_login_dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.94);
    z-index: 1000;
    color: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: scale(1.2);
    pointer-events: none;
    opacity: 0;
    transition: all 450ms ease-in;
    
    .es_error {
      max-width: 70vw;
      margin: 0 auto;
      text-align: center;
      color: $yellow-color;
      
      b {
        display: block;
      }
    }
    
    .es_close_icon {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: rgba(255,255,255,0.3);
      color: rgba(0,0,0,0.94);
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1001;
      text-align: center;
      line-height: 50px;
      font-size: 34px;
      font-weight: 200;
    }
    
    &.es_active {
      transform: scale(1);
      opacity: 1;
      pointer-events: auto;
    }
  }
  
  .es_btn_group {
    display: flex;
    
    .es_btn {
      margin: 0 !important;
    }
  }
  
  .es_access_code_panel {
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 80vw;
    margin: 0 auto;
    border-radius: 5px;
    padding: 8px;
    margin-top: 24px;
    
    .es_h3 {
      font-size: 13px !important;
    }
    
    .es_access_code {
      font-size: 38px;
      color: $white-color;
      font-weight: 700;
      text-align: center;
      letter-spacing: 2px;
      display: block;
    }
  }
  
  .es_submit_form_panel {    
    a.es_btn {
      display: inline-block;
      width: 40%;
      max-width: 260px;
      height: 50px;
      margin: 24px 12px 0;
      background-color: $base-color;
      color: $white-color;
      border: 0;
      font-size: 18px;
      border-radius: 2px;
      font-weight: 600;
      text-transform: uppercase;
      width: 80vw;
      margin: 20px auto;
      display: block;
      text-align: center;
      line-height: 52px;
      
      &.es_disabled {
        opacity: 0.4;
        cursor: not-allowed;
        background-color: $black-light-color;
      }
    }
  }
  
  .es_user_signature {
    background-color: white;
    height: 200px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    transform: translateX(-10vw);
    margin-top: 16px;
    margin-bottom: 16px;
    
    .es_clear_icon {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background-color: rgba(0,0,0,0.1);
      color: rgba(0,0,0,0.4);
      position: absolute;
      top: 24px;
      right: 34px;
      z-index: 10;
      text-align: center;
      line-height: 40px;
      font-size: 28px;
      font-weight: 200;
    }
    
    .es_h2 {
      position: absolute;
      bottom: 20%;
      left: 50%;
      width: 78%;
      transform: translateX(-50%);
      pointer-events: none;
      border-top: 2px dashed $base-color;
      text-align: center;
      padding-top: 12px;
      font-weight: 300 !important;
      font-size: 14px !important;
      color: rgba(0,0,0,0.5) !important;
    }
    
    & > .es_canvas {
      background-color: $white-color;
      width: 100%;
      height: 100%;
    }
    
    .es_invalid_note {
      font-size: 16px;
      text-align: center;
      display: block;
      font-weight: 500;
      max-width: 250px;
      margin: 24px auto 0;
      color: $red-color;
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .es_questionnaire_details {
    margin-top: 60px;
    
    & > .es_h2 {
      margin: 20px auto;
      font-size: 18px;
      font-weight: 500;
      max-width: 250px;
      text-align: center;
    }
    
    & > .es_postamble,
    & > .es_preamble {
      margin: 20px auto;
      font-size: 18px;
      font-weight: 400;
      margin: 20px;
      line-height: 1.35em;
      font-size: 13px;
      text-align: center;
    }
    
    .es_invalid_note {
      font-size: 16px;
      text-align: center;
      display: block;
      font-weight: 500;
      max-width: 250px;
      margin: 24px auto 0;
      color: $red-color;
    }
  }
  
  .es_visitor_details {
    & > .es_h5 {
      margin: 20px auto;
      font-size: 18px;
      font-weight: 500;
      max-width: 90%;
      text-align: center;
      
      .es_disclaimer {
        margin-top: 8px;
        font-size: 12px;
      }
      
      .es_b {
        display: block;
      }
      
      .es_a {
        font-size: 13px;
        color: $base-color;
        text-decoration: underline;
      }
    }
  }
  
  .es_label {
    display: block;
  }
  
  .es_form_input {
    overflow: hidden;
    text-align: center;
    padding: 14px 26px;
    background-color: rgba(0,0,0,0.05);
    
    &.es_last {
      border-bottom: 1px solid rgba(0,0,0,0.2);
      padding-bottom: 24px;
    }
    
    &.es_first {
      padding-top: 24px;
    }
    
    .es_label > b {
      font-size: 16px;
      text-align: center;
      display: block;
      font-weight: 500;
      max-width: 250px;
      margin: 0 auto 15px;
      
      &.es_invalid_note {
        color: $red-color;
      }
    }
    
    .es_optional_note,
    .es_required_note {
      font-size: 11px;
      color: &black-color;
      display: block;
      text-align: center;
      margin-top: 6px;
      opacity: 0.6;
    }

    .es_required_note_big {
      font-size: 13px;
      color: &black-color;
      display: block;
      text-align: center;
      margin-top: 6px;
      opacity: 0.6;
    }
    
    a.es_btn {
      display: inline-block;
      width: 86%;
      max-width: 260px;
      height: 70px;
      margin: 24px 12px 0;
      border: 1px solid rgba(0,0,0,0.2);
      background-color: $white-color;
      font-size: 18px;
      border-radius: 2px;
      line-height: 72px;
      text-align: center;
      
      &.es_active {
        border: 2px solid $base-color;
        color: $base-color;
      }
    }
    
    &.es_invalid {
      .es_input {
        border: 1px solid $red-color !important;
      }
    }
    
    .es_h2 {
      font-size: 18px;
      font-weight: 600;
      max-width: 80vw;
      margin: 0 auto;
      text-align: center;
      line-height: 1.2em;
    }
    
    .es_input {
      display: block;
      border: 1px solid rgba(0,0,0,0.2);
      width: 100%;
      margin: 5px 0;
      font-size: 18px;
      padding: 14px 20px;
      box-sizing: border-box;
      border-radius: 2px;
      background-color: $white-color;
      outline: 0;
      
      &:focus {
        border: 1px solid $base-color;
      }
    }
          
    
    &.es_active,
    &.es_completed {
      max-height: 100vh;
      opacity: 1;
    }
  }
  
  .es_hero_panel {
    position: fixed;
    top: -70px;
    transition: top 250ms ease-in;
    left: 0;
    height: 60px;
    align-items: center;
    width: 100vw;
    display: flex;
    justify-items: center;
    z-index: 100;
    background-color: $black-light-color;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.14);
    
    &.es_visible {
      top: 0;
    }
    
    .es_h3 {
      color: $white-color;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      width: 100%;
    }
  }

  .es_loading_hero {
    height: 100vh;
    overflow: hidden;
    background-color: $black-light-color;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition: height 500ms ease-out;
    z-index: 10000;
    transition: transform 500ms ease-out;
        
    .es_unlocked {
      margin: 20px auto;
      display: block;
      height: 76px;
    }
    
    &.es_hide {
      display: none;
      transform: translateY(-100vh);
    }
    
    .es_logo {
      width: 64vw;
      display: block;
      margin: 8vh auto 0;
      transition: margin 400ms ease-out;
    }
    
    .es_h5 {
      color: $white-color;
      text-align: center;
      font-weight: 300;
      font-size: 19px;
      margin-bottom: 24px;
      max-width: 88vw;
      margin-left: auto;
      margin-right: auto;
      margin-top: -16px;
    }
    
    .es_h2 {
      color: $white-color;
      font-size: 30px;
      font-weight: 600;
      margin: 30px auto;
      text-align: center;
      line-height: 1.2em;
      margin-top: 70px;
    }
    
    .es_h3 {
      color: $white-color;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
    }
    
    .es_welcome,
    .es_error {
      max-width: 200px;
      text-align: center;
      font-size: 18px;
      color: $white-color;
      line-height: 1.3em;
      margin-left: auto;
      margin-right: auto;
      height: 140px;
      opacity: 0;
      transition: all 320ms ease-out 300ms;
      
      &.es_active {
        opacity: 1;
      }
      
      .es_b {
        font-size: 48px;
        display: block;
        margin-bottom: 16px;
      }
    }
    
    .es_welcome {
      width: 320px;
      max-width: 320px;
      padding-top: 6vh;
      height: 300px;
      
      a.es_btn {
        display: block;
        width: 40%;
        max-width: 260px;
        height: 50px;
        margin: 24px 12px 0;
        background-color: $base-color;
        color: $white-color;
        border: 0;
        font-size: 18px;
        border-radius: 2px;
        font-weight: 600;
        text-transform: uppercase;
        width: 80vw;
        margin: 20px auto;
        display: block;
        text-align: center;
        line-height: 52px;
        
        &.es_disabled {
          opacity: 0.4;
          cursor: not-allowed;
          background-color: $black-light-color;
        }
      }
    }
    
    .es_loader {
      pointer-events: none;
      opacity: 1;
      transition: opacity 320ms ease-out, height 100ms ease-out 600ms;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      overflow: hidden;;
      padding-bottom: 20px;
      margin-top: 80px;
      
      &.es_hide {
        opacity: 0;
        height: 0;
        margin: 0;
      }
    }
  }
</style>

<script>
import Spinner from '@/components/Spinner.vue'
import TrackVisibility from '@/components/TrackVisibility.vue'
import SignaturePad from 'signature_pad'

import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signOut, signInAnonymously, onAuthStateChanged } from "firebase/auth"
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRoute } from 'vue-router'

export default {
  name: 'PropertyListing',
  components: {
    Spinner,
    TrackVisibility,
    SignaturePad
  },
  data() {
    return { 
      emailAddress: null,
      password: null,
      loginErrorMessage: null,
      propertyId: null,
      questionnaireId: null,
      loadingData: true,
      errorMessage: null,
      propertyDetails: null,
      questionnaire: null,
      questions: null,
      visitor: {},
      visit: {},
      showAccessCode: false,
      userDetailsFilledIn: false,
      completedFields: {},
      invalidFields: {},
      signaturePad: null,
      showWelcomeMessage: false,
      submittingForm: false,
      showLogin: false,
      showSignIn: false,
      formSubmitError: null,
      activeFormStep: 0
    }
  },
  methods: {
    async submitForm() {
      if (!this.hasSignature()) {
        this.invalidFields['signature'] = true;
      } else {
        this.invalidFields['signature'] = false;
      }
      
      if (this.submittingForm) {
        return false;
      }
      
      this.activeFormStep++;
      this.formSubmitError = null;
      this.submittingForm = true;

      const vm = this;
      const auth = getAuth();
      const db = getFirestore();
      const analytics = getAnalytics();
      
      let familyName = this.visitor.fullname.split(' ')[0];
      let givenName = this.visitor.fullname.substring(this.visitor.fullname.indexOf(' ') + 1);
      
      let visitorData = {
        createdTimestamp: Timestamp.fromDate(new Date()),
        email: this.visitor.email,
        familyName: familyName,
        givenName: givenName,
        phoneNumber: this.visitor.phone,
        address: {},
        _version: 1
      }
      
      if (this.visitor.postalCode) {
        visitorData.address.postalCode - this.visitor.postalCode;
      }
            
      const visitorRef = await addDoc(collection(db, "visitors"), visitorData);
      
      let visitorId = visitorRef.id;
      var signatureCanvas = document.querySelector("#user_signature_canvas");
      let signatureDateURL = this.cropSignatureCanvas(signatureCanvas);
      let signatureBase64 = signatureDateURL.replace(/^data:image\/(png|jpg);base64,/, "");
      
      let visitData = {
        visitTimestamp: Timestamp.fromDate(new Date()),
        visitor: doc(db, "visitors/" + visitorId),
        visitorType: this.visitor.visitType,
        signatureImageData: Bytes.fromBase64String(signatureBase64),
        questionnaire: doc(db, "questionnaires/" + this.questionnaireId),
        formattedName: this.visitor.fullname,
        _version: 2,
        customerRelationship: "unknown",
        isVIP: false,
        answers: this.visit.answers || []
      };
      
      if (this.visitor.visitType === 'other') {
        visitData.vistTypeName = this.visitor.visitTypeOther;
      }

      if (this.visitor.visitType === 'buyer' && this.visitor.representingAgent) {
        visitData.representingAgent = this.visitor.representingAgent;
      }

      if (this.visitor.visitType === 'agent') {
        this.showAccessCode = true;
        
        visitData.brokerageName = this.visitor.brokerageName;
        visitData.licenseNumber = this.visitor.licenseNumber;
        visitData.numberOfVisitors = this.visitor.numberOfVisitors;
      }
      
      const visitRef = await addDoc(collection(db, "propertyListings", this.propertyId, "visits"), visitData);
      let formattedAddress = this.getStreetName() + ", " + this.propertyDetails.address.locality;
      
      logEvent(analytics, 'notification_received');
      
      let visitEventData = {
        visitor_name: this.visitor.fullname,
        visitor_email: this.visitor.email,
        visitor_phone: this.visitor.phone,
        visitor_postalCode: this.visitor.postalCode,
        visit_type: this.visitor.visitType,
        property_address: formattedAddress
      };
      
      if (this.visitor.visitType === 'other') {
        visitEventData.visit_type_name = this.visitor.visitTypeOther;
      }
      
      if (this.visitor.visitType === 'agent') {
        visitEventData.visit_agent_brokerage_name = this.visitor.brokerageName;
        visitEventData.visit_agent_license_number = this.visitor.licenseNumber;
        visitEventData.visit_agent_number_of_visitors = this.visitor.numberOfVisitors;
      }
      
      logEvent(analytics, 'property_visit', visitEventData);
      this.storeDataToLocalStorage();
      
      vm.showWelcomeMessage = true;
    },
    activateInput(name) {
      window.setTimeout(function() {
        document.querySelector(name).focus();
      }, 750);
    },
    getStreetName() {
      if (!this.propertyDetails || !this.propertyDetails.address) {
        return "Unknown";
      }
      
      if (this.propertyDetails.address.street1) {
        let streetName = this.propertyDetails.address.street1;
        
        if (this.propertyDetails.address.street2) {
          streetName += " " + this.propertyDetails.address.street2;
        }
        
        return streetName;
      }
      
      return this.propertyDetails.address.street;
    },
    getTotalFormSteps() {
      if (this.visitor && this.visitor.visitType === 'agent') {
        return 10;
      }
      
      return 8;
    },
    storeDataToLocalStorage() {
      window.localStorage.setItem('ES_VISITOR', JSON.stringify(this.visitor));
    },
    loadDataFromLocalStorage() {
      let dataFromStorage = window.localStorage.getItem('ES_VISITOR');
      let vm = this;
      
      if (dataFromStorage) {
        try {
          let parsedData = JSON.parse(dataFromStorage);
          this.visitor = parsedData;
        } catch(e) {
          console.log(e);
        }
      }
    },
    clearSignature() {
      if (this.signaturePad) {
        this.signaturePad.clear();
      }
    },
    hasSignature() {
      return this.signaturePad && !this.signaturePad.isEmpty();
    },
    submitAnswer() {
      if (!this.visit.answers) {
        this.visit.answers = [];
      }
      
      let vm = this;
      
      for (let i = 0; i< vm.questions.length; i++) {
        vm.visit.answers[i] = true;
      }
    },
    getAnswer() {
      return this.visit.answers && this.visit.answers[0];
    },
    checkInvalid(property) {
      return this.invalidFields[property];
    },
    validateType(type) {
      return type && type.length > 0;
    },
    validateFullname(name) {
      return name && name.split(' ').length > 1;
    },
    validatePassword(password) {
      return password && password.length >= 8;
    },
    validatePostalCode(postalCode) {
      const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      return re.test(String(postalCode).toLowerCase());
    },
    validatePhone(phone) {
      const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return re.test(String(phone).toLowerCase());
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateVisitors(visitType, numberOfVisitors) {
      return visitType !== 'agent' || (!isNaN(numberOfVisitors) && numberOfVisitors >= 0);
    },
    validateLicense(visitType, licenseNumber) {
      return visitType !== 'agent' || (licenseNumber && licenseNumber.length > 2);
    },
    validateBrokerage(visitType, brokerageName) {
      return visitType !== 'agent' || (brokerageName && brokerageName.length > 2);
    },
    validateTypeOther(visitType, typeOthers) {
      return visitType !== 'other' || (typeOthers && typeOthers.length > 2);
    },
    validateProperty(property, focusField) {
      this.validateUserData(property);  
      
      if (this.invalidFields[property]) {
        return false;
      }
      
      this.activeFormStep = this.activeFormStep + 1;
      
      if (focusField) {
        this.activateInput(focusField);
      }
    },
    validateUserData(propertyName) {
      var allValid = true;
      let fullValidation = false;
      
      if (propertyName == 'fullname' || fullValidation) {
        if (!this.visitor.fullname) {
          this.invalidFields['fullname'] = 'Please enter your full name.';
          allValid = false;
        } else if (this.visitor.fullname && !this.validateFullname(this.visitor.fullname)) {
          this.invalidFields['fullname'] = 'Please enter both your family and your given name.';
          allValid = false;
        } else {
          this.invalidFields['fullname'] = false;
        }
      }
      
      if (propertyName == 'email' || fullValidation) {
        if (!this.visitor.email) {
          this.invalidFields['email'] = 'Please enter your email address.';
          allValid = false;
        } else if (this.visitor.email && !this.validateEmail(this.visitor.email)) {
          this.invalidFields['email'] = 'Please enter a valid email address.';
          allValid = false;
        } else {        
          this.invalidFields['email'] = false;
        }
      }    
      
      if (propertyName == 'visitType' || fullValidation) {
        if (!this.validateType(this.visitor.visitType)) {
          this.invalidFields['visitType'] = 'Please select the visit type.';
          allValid = false;
        } else {
          this.invalidFields['visitType'] = false;
        }
      }

      if (propertyName == 'agreeWithTerms' || fullValidation) {
        if (!this.visitor.agreeWithTerms) {
          this.invalidFields['agreeWithTerms'] = true;
          allValid = false;
        } else {
          this.invalidFields['agreeWithTerms'] = false;
        }
      }

      if (propertyName == 'typeOther' || fullValidation) {
        if (!this.validateTypeOther(this.visitor.visitType, this.visitor.visitTypeOther)) {
          this.invalidFields['typeOther'] = 'Please enter the purpose of your visit.';
          allValid = false;
        } else {
          this.invalidFields['typeOther'] = false;
        }
      }

      if (propertyName == 'brokerageName' || fullValidation) {
        if (!this.validateBrokerage(this.visitor.visitType, this.visitor.brokerageName)) {
          this.invalidFields['brokerageName'] = 'Please enter the brokerage name.';
          allValid = false;
        } else {
          this.invalidFields['brokerageName'] = false;
        }
      }

      if (propertyName == 'licenseNumber' || fullValidation) {
        if (!this.validateLicense(this.visitor.visitType, this.visitor.licenseNumber)) {
          this.invalidFields['licenseNumber'] = 'Please enter you license number.';
          allValid = false;
        } else {
          this.invalidFields['licenseNumber'] = false;
        }
      }

      if (propertyName == 'numberOfVisitors' || fullValidation) {
        if (!this.validateVisitors(this.visitor.visitType, this.visitor.numberOfVisitors)) {
          this.invalidFields['numberOfVisitors'] = 'Please enter number of visitors in your party.';
          allValid = false;
        } else {
          this.invalidFields['numberOfVisitors'] = false;
        }
      }
            
      return allValid;
    },
    initSignaturePad() {
      if (!this.signaturePad) {
        let vm = this;
        
        window.setTimeout(() => {
          var canvas = document.querySelector("#user_signature_canvas");
          
          vm.signaturePad = new SignaturePad(canvas, {
            onEnd: () => {
              vm.$forceUpdate()
            }
          });
          
          var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    
          // This part causes the canvas to be cleared
          canvas.width = canvas.offsetWidth * ratio;
          canvas.height = canvas.offsetHeight * ratio;
          
          canvas.getContext("2d").scale(ratio, ratio);
        }, 500);
      }
    },
    cropSignatureCanvas(canvas) {
      // First duplicate the canvas to not alter the original
      var croppedCanvas = document.createElement('canvas'),
          croppedCtx    = croppedCanvas.getContext('2d');

          croppedCanvas.width  = canvas.width;
          croppedCanvas.height = canvas.height;
          croppedCtx.drawImage(canvas, 0, 0);

      // Next do the actual cropping
      var w         = croppedCanvas.width,
          h         = croppedCanvas.height,
          pix       = {x:[], y:[]},
          imageData = croppedCtx.getImageData(0,0,croppedCanvas.width,croppedCanvas.height),
          x, y, index;

      for (y = 0; y < h; y++) {
          for (x = 0; x < w; x++) {
              index = (y * w + x) * 4;
              if (imageData.data[index+3] > 0) {
                  pix.x.push(x);
                  pix.y.push(y);

              }
          }
      }
      pix.x.sort(function(a,b){return a-b});
      pix.y.sort(function(a,b){return a-b});
      var n = pix.x.length-1;

      w = pix.x[n] - pix.x[0];
      h = pix.y[n] - pix.y[0];
      var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

      croppedCanvas.width = w;
      croppedCanvas.height = h;
      croppedCtx.putImageData(cut, 0, 0);

      return croppedCanvas.toDataURL();
    },
    async init(propertyId) {
      const vm = this;
      const auth = getAuth();
      const user = auth.currentUser;
      const db = getFirestore();
  
      if (!user) {
        let singInResponse = await signInAnonymously(auth);
        
        if (singInResponse.error) {
          vm.errorMessage = `An error occured while communicating with the server, code: ${error.code}, message: ${error.message}`;
          console.log(vm.errorMessage);
          vm.loadingData = false;
        }    
      }
  
      const docRef = doc(db, "propertyListings", propertyId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        let propertyDetails = docSnap.data(); 
        
        if (propertyDetails.archived) {
          vm.errorMessage = "Property was not found or is archived.";
          vm.loadingData = false;
  
          return false;
        }
        
        vm.propertyDetails = propertyDetails;
        vm.propertyId = docSnap.id;
        vm.loadDataFromLocalStorage();
        
        // set document title
        document.title = `${vm.getStreetName()} | eSentry.net`;
        
        const docRefQuestionnaire = doc(db, "questionnaires", "YehW1KWAaKMgYtHKITTw");
        const docSnapQuestionnaire = await getDoc(docRefQuestionnaire);
        
        if (docSnapQuestionnaire.exists()) {
          const questionnaireId = docSnapQuestionnaire.id;
          vm.questionnaire = docSnapQuestionnaire.data();
          vm.questionnaireId = questionnaireId;
          
          const docRefQuestions = collection(db, "questionnaires", questionnaireId, "questions");
          const docSnapQuestions = await getDocs(docRefQuestions);
          
          vm.questions = [];
          
          docSnapQuestions.forEach((question) => {
            vm.questions.push(question.data());
          });
          
          vm.loadingData = false;
          vm.initSignaturePad();
        } else {
          vm.errorMessage = "Property's questionnaire was not found or is archived.";
          vm.loadingData = false;
        }
      } else {
        vm.errorMessage = "Property was not found or is archived.";
        vm.loadingData = false;
      }
    }
  },  
  async created() {
    const route = useRoute();
    const vm = this;
    const propertyId = route.params.propertyId;
    
    if (!propertyId) {
      vm.errorMessage = "Property was not found or is archived.";
      vm.loadingData = false;
      
      return false;
    }
    
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      // firebase auth is initialized now
      vm.init(propertyId);
    });
    
  }
}
</script>