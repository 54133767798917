<template>
  <div class="admin-content-panel py-lg-15 d-flex flex-column-fluid align-items-start container-xxl">
    <div class="content flex-row-fluid" id="kt_content">
      
      <div class="card mb-5 mb-xl-8">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">
              <router-link class="btn btn-link fw-bolder btn-color-gray-900 fs-1" :to="{ name: 'PortalAgents' }">Personal Information</router-link>
            </span>
          </h3>
        </div>
        <div class="card-body py-3">
          <div id="kt_account_settings_profile_details" class="collapse show">
            <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">
              <div class="card-body border-top p-9">
                
                <div class="row mb-6">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">Fullname</label>
                  <div class="col-lg-8 fv-row fv-plugins-icon-container">
                    <input type="text" name="name" class="form-control form-control-lg form-control-solid" placeholder="Fullname" v-model="agentDetails.name">
                  </div>
                </div>
                
                <div class="row mb-6">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">Email Address</label>
                  <div class="col-lg-8 fv-row fv-plugins-icon-container">
                    <input type="text" name="name" class="form-control form-control-lg form-control-solid" placeholder="Email Address" v-model="agentDetails.email" readonly="true">
                  </div>
                </div>
                
                <div class="row mb-6">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">Phone Number</label>
                  <div class="col-lg-8 fv-row fv-plugins-icon-container">
                    <input type="text" name="name" class="form-control form-control-lg form-control-solid" placeholder="Phone Number" v-model="agentDetails.phoneNumber" readonly="true">
                  </div>
                </div>
                
                <div class="row mb-6">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">License Number</label>
                  <div class="col-lg-8 fv-row fv-plugins-icon-container">
                    <input type="text" name="name" class="form-control form-control-lg form-control-solid" placeholder="License Number" v-model="agentDetails.licenseNumber" readonly="true">
                  </div>
                </div>
                
                <div class="row mb-6">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">Brokerage Name</label>
                  <div class="col-lg-8 fv-row fv-plugins-icon-container">
                    <input type="text" name="name" class="form-control form-control-lg form-control-solid" placeholder="Brokerage Name" v-model="agentDetails.brokerageName" readonly="true">
                  </div>
                </div>
                
                <div class="row mb-6">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">Brokerage License Number</label>
                  <div class="col-lg-8 fv-row fv-plugins-icon-container">
                    <input type="text" name="name" class="form-control form-control-lg form-control-solid" placeholder="Brokerage License Number" v-model="agentDetails.brokerageLicenseNumber" readonly="true">
                  </div>
                </div>
                
              </div>
              <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button>
                <a @click="save()" :data-kt-indicator="submittingForm ? 'on' : 'off'" class="btn btn-primary">
                  <span class="indicator-label">Save Changes</span>
                  <span class="indicator-progress">Saving...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </a>
              </div>
            <input type="hidden"><div></div></form>
          </div>
        </div>
      </div>
      
      
      
      <div class="card mb-5 mb-xl-8">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">
              <router-link class="btn btn-link fw-bolder btn-color-gray-900 fs-1" :to="{ name: 'PortalAgents' }">Integrations</router-link>
            </span>
          </h3>
        </div>
        <div class="card-body py-3">
          
          <div id="kt_account_settings_profile_details" class="collapse show" data-select2-id="select2-data-kt_account_settings_profile_details">

              <div class="card-body border-top p-9" data-select2-id="select2-data-136-mwl4">


<div class="d-flex flex-stack">
                <div class="d-flex">
                  <img src="../assets/liondesk-logo.png" class="w-40px me-6" alt="">
                  <div class="d-flex flex-column">
                    <span class="fs-5 text-dark fw-bolder">LionDesk</span>
                    <div class="fs-6 fw-bold text-gray-400">Use the 'Connect'  button to connect your LionDesk account. </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <a data-kt-indicator="on" class="btn btn-secondary" v-if="!lionDeskAuthorization">
                    <span class="indicator-progress">Loading...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                  </a>
                  <a class="btn btn-primary" :href="lionDeskAuthorization.authorizationLink" target="_blank" v-if="lionDeskAuthorization && !lionDeskAuthorization.accessToken">
                    <span class="indicator-label">Connect</span>
                  </a>
                  <a class="btn btn-secondary" v-if="lionDeskAuthorization && lionDeskAuthorization.accessToken" @click="disconnectLionDeskAuthorization()">
                    <span class="indicator-label">Disconnect</span>
                  </a>
                </div>
              </div>
              <div class="separator separator-dashed my-5"></div>
              <div class="d-flex flex-stack">
                <div class="d-flex">
                  <img src="../assets/followupboss-logo.png" class="w-40px me-6" alt="">
                  <div class="d-flex flex-column">
                    <span class="fs-5 text-dark fw-bolder">Follow Up Boss</span>
                    <div class="fs-6 fw-bold text-gray-400">Use the 'Connect'  button to connect your Follow Up Boss account. </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <a data-kt-indicator="on" class="btn btn-secondary" v-if="!followUpBossAuthorization">
                    <span class="indicator-progress">Loading...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                  </a>
                  <a class="btn btn-primary" v-if="followUpBossAuthorization && !followUpBossAuthorization.apiAccessToken" @click="showFollwUpBossDialog = true">
                    <span class="indicator-label">Connect</span>
                  </a>
                  <a class="btn btn-secondary" v-if="followUpBossAuthorization && followUpBossAuthorization.apiAccessToken" @click="disconnectFollowUpBossToken()">
                    <span class="indicator-label">Disconnect</span>
                  </a>
                </div>
              </div>
              <div class="separator separator-dashed my-5"></div>

              </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
  
  <div class="modal fade show" id="kt_modal_new_target" tabindex="-1" v-if="showFollwUpBossDialog" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content rounded">
        <div class="modal-header pb-0 border-0 justify-content-end">
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" @click="showFollwUpBossDialog = false">
            <span class="svg-icon svg-icon-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
              </svg>
            </span>
          </div>
        </div>
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <form id="kt_modal_new_target_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">
            <div class="mb-13 text-center">
              <h1 class="mb-3">Follow Up Boss Access</h1>              
            </div>
            <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <p>Please login to your Follow Up Boss account and go to: <b>'Admin → API → Create API Key'</b> in orderd to generate the API token.</p>
              
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">API Key</span>
              </label>
              <input type="text" class="form-control form-control-solid" placeholder="Enter Follow Up Boss API Key..." v-model="followUpBossToken" @keyup.enter="saveFollowUpBossToken()">
            </div>
  
            <div class="text-center">
              <button type="reset" id="kt_modal_new_target_cancel" @click="showFollwUpBossDialog = false" class="btn btn-light me-3">Cancel</button>
              <span id="kt_modal_new_target_submit" @click="saveFollowUpBossToken()" class="btn btn-primary">
                <span class="indicator-label">Save</span>
              </span>
            </div>
          <div></div></form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signOut, signInAnonymously, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, orderBy, where, addDoc, setDoc, Timestamp, Bytes, deleteDoc } from "firebase/firestore"
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'PortalSettings',
  data() {
    return { 
      agentDetails: {},
      agentDocRef: null,
      submittingForm: false,
      showFollwUpBossDialog: false,
      followUpBossToken: null,
      lionDeskAuthorization: null,
      followUpBossAuthorization: null
    }
  },
  methods: {
    async disconnectFollowUpBossToken() {
      const auth = getAuth();
      const db = getFirestore();
      const user = auth.currentUser;
      const userId = user.uid;
      
      this.followUpBossAuthorization = null;
      await deleteDoc(doc(db, "followUpBossAuthorizations", userId));
      this.followUpBossAuthorization = {};
    },
    async saveFollowUpBossToken() {
      const auth = getAuth();
      const db = getFirestore();
      const user = auth.currentUser;
      const userId = user.uid;
      
      const followUpBossRef = doc(db, "followUpBossAuthorizations", userId);
      const followUpBossAuthorization = {
        "apiAccessToken": this.followUpBossToken
      };
      
      await setDoc(followUpBossRef, followUpBossAuthorization);
      this.followUpBossAuthorization = followUpBossAuthorization;
      
      this.showFollwUpBossDialog = false;
    },
    async disconnectLionDeskAuthorization() {
      if (this.lionDeskAuthorization && this.lionDeskAuthorization.authorizationLink) {
        const auth = getAuth();
        const db = getFirestore();
        const user = auth.currentUser;
        const userId = user.uid;
        
        this.lionDeskAuthorization = {
          "authorizationLink": this.lionDeskAuthorization.authorizationLink
        };
        
        const lionDeskRef = doc(db, "lionDeskAuthorizations", userId);
        await setDoc(lionDeskRef, this.lionDeskAuthorization);
      }
    },
    async save() {
      const vm = this;
      vm.submittingForm = true;
      await setDoc(vm.agentDocRef.ref, vm.agentDetails, { merge: true });
      vm.submittingForm = false;
    },
    async init() {
      const auth = getAuth();
      const db = getFirestore();
      const vm = this;
      const user = auth.currentUser;
      const userId = user.uid;
      
      let docRefAgents = query(collection(db, "agents"), limit(1), where("uid", "==", userId));
      let docAgents = await getDocs(docRefAgents);
      
      docAgents.forEach(agent => {
        vm.agentDocRef = agent; 
        vm.agentDetails = agent.data();
      });
      
      // load liondesk auth token
      const docLionDeskAuth = doc(db, "lionDeskAuthorizations", userId);
      const snapLionDeskAuth = await getDoc(docLionDeskAuth);
      
      vm.lionDeskAuthorization = {};
      
      if (snapLionDeskAuth.exists()) {
        vm.lionDeskAuthorization = snapLionDeskAuth.data();
      }
      
      // load followupboss auth token
      const docFollowUpBossAuth = doc(db, "followUpBossAuthorizations", userId);
      const snapFollowUpBossAuth = await getDoc(docFollowUpBossAuth);
      
      vm.followUpBossAuthorization = {};
      
      if (snapFollowUpBossAuth.exists()) {
        vm.followUpBossAuthorization = snapFollowUpBossAuth.data(); 
      }
    }
  },
  async created() {
    const vm = this;
    const auth = getAuth();
    
    onAuthStateChanged(auth, (user) => {
      // firebase auth is initialized now
      vm.init();
    });
  }
}
</script>