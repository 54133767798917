<template></template>

<script>
export default {
  name: 'PortalPanelsCollapser',
  created() {
  	let adminMainContainer = null;
	  	
	window.setInterval(() => {
		if (document.querySelector('.admin-main-container')) {
			adminMainContainer = document.querySelector('.admin-main-container');
		}
		
		if (adminMainContainer) {
			let adminPanels = adminMainContainer.querySelectorAll(':scope > .admin-content-panel');
			
			adminPanels.forEach((element, index, arr) => {
				let size = arr.length;
				
				if (index === size - 1) {
					element.classList.remove('admin-content-panel-collapsed');
				} else {
					element.classList.add('admin-content-panel-collapsed');
					element.classList.add('admin-content-panel-collapsed-' + (size - index));
				}
			});
		}
	}, 250);
  }
}
</script>