<template>
  <div class="table-responsive">
    <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 dataTable">
      <thead>
        <tr class="fw-bolder text-muted">
          <th>Full Name</th>
          <th>Vist Time</th>
        </tr>
        
      </thead>
      <tbody>
        <tr v-for="visit in visitsList" v-bind:key="visit.vistUid">
          <td><router-link :to="{ name: detailPathName, params: { 'visitUid': visit.vistUid } }" append>{{visit.formattedName}}</router-link></td>
          <td>
            <span class="text-dark fw-bolder">{{visit.visitTimestamp.toDate().toLocaleString()}}</span>
          </td>
        </tr>
        
        <tr v-if="loadingData">
          <td  colspan="6" class="text-center">Loading data...</td>
        </tr>
  
        <tr v-if="!loadingData && visitsList.length === 0">
          <td  colspan="6" class="text-center">No visits found...</td>
        </tr>
  
      </tbody>
    </table>
  </div>
  
</template>

<script>
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, orderBy, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'PortalVisits',
  data() {
    return { 
      visitsList: null,
      loadingData: false,
      detailPathName: null
    }
  },
  methods: {
    async init(propertyUid) {
      const db = getFirestore();
      const vm = this;
  
      vm.loadingData = true;
  
      let docRefProperties = query(collection(db, "propertyListings", propertyUid, "visits"), limit(1000));
      const docSnapProperties = await getDocs(docRefProperties);
      
      vm.visitsList = [];
      docSnapProperties.forEach(visit => {
        let visitData = visit.data();
        visitData.vistUid = visit.id;
        
        vm.visitsList.push(visitData);
      });
      
      vm.loadingData = false;
    }
  },
  props: ['propertyUid'],
  async created() {
    const vm = this;
    
    vm.detailPathName = 'PortalAgentsAgentPropertyVisit';
    
    let currentPathName = useRouter().currentRoute.value.name || '';
    if (currentPathName.indexOf('PortalProperties') === 0) {
      vm.detailPathName = 'PortalPropertiesPropertyVisit';
    }
    
    vm.init(vm.propertyUid);
  }
}
</script>