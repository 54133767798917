<template>
  <div class="admin-content-panel py-lg-15 d-flex flex-column-fluid align-items-start container-xxl">
    <div class="content flex-row-fluid" id="kt_content">
      
      <div class="card mb-5 mb-xl-8">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <router-link class="btn btn-link fw-bolder btn-color-gray-900 fs-1 admin-content-panel-back-link" :to="{ name: detailPathName, params: { 'propertyUid': propertyUid } }">Open House Detail</router-link>
          </h3>
          
          <span class="admin-close-card-icon" @click="$router.go(-1)">
            <span class="svg-icon svg-icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect></svg></span>
          </span>
        </div>
        <div class="card-body py-3">
          
          
          
          
          
          
          
          
          <div class="card mb-5 mb-xl-10">
            <div class="card-body pt-9 pb-0">
              <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                <div class="me-7 mb-4">
                  <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                    <img src="../assets/metronic-ui-kit/avatars/avatar-home.png" alt="image">
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center mb-2">
                        <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{{formatPropertyAddress(propertyDetail)}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap flex-stack">
                    <div class="d-flex flex-column flex-grow-1 pe-8">
                      <div class="d-flex flex-wrap">
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div class="d-flex align-items-center">
                            <div class="fs-2 fw-bolder">{{'-'}}</div>
                          </div>
                          <div class="fw-bold fs-6 text-gray-400">Visits</div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div class="d-flex align-items-center">
                            <div class="fs-2 fw-bolder">{{propertyDetail.seller || '-'}}</div>
                          </div>
                          <div class="fw-bold fs-6 text-gray-400">Seller</div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div class="d-flex align-items-center">
                            <div class="fs-2 fw-bolder">{{propertyDetail.address ? propertyDetail.address.postalCode : '-'}}</div>
                          </div>
                          <div class="fw-bold fs-6 text-gray-400">Postal Code</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          
          
          
          
          
          
          
          
          
          <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div class="card-header cursor-pointer">
              <div class="card-title m-0">
                <h3 class="fw-bolder m-0">Open House Details</h3>
              </div>
            </div>
            
            <div class="card-body p-9">
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Seller Name</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{propertyDetail.seller}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Street</label>
                <div class="col-lg-8 fv-row">
                  <span class="fw-bolder fs-6 text-gray-800">{{propertyDetail.address ? propertyDetail.address.street1 : '-'}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Apartment, suite, etc.</label>
                <div class="col-lg-8 d-flex align-items-center">
                  <span class="fw-bolder fs-6 text-gray-800">{{propertyDetail.address ? propertyDetail.address.street2 : '-'}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">City</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{propertyDetail.address ? propertyDetail.address.locality : '-'}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">State</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{propertyDetail.address ? propertyDetail.address.administrativeArea : '-'}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">ZIP Code</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{propertyDetail.address ? propertyDetail.address.postalCode : '-'}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Country</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{propertyDetail.address ? propertyDetail.address.country : '-'}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Created</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800" v-if="propertyDetail.createdTimestamp">{{propertyDetail.createdTimestamp.toDate().toLocaleString()}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Property UID</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{propertyDetail.id}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Agent UID</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{propertyDetail.uid}}</span>
                </div>
              </div>
            </div>
          </div>
          
          
          
          
          
          
          <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div class="card-header cursor-pointer">
              <div class="card-title m-0">
                <h3 class="fw-bolder m-0">Open House Visits</h3>
              </div>
            </div>
            
            <div class="card-body p-9">
              
              <PortalVisits v-if="propertyDetail.id" :propertyUid="propertyDetail.id"></PortalVisits>
              
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>

  <router-view></router-view>
</template>


<script>
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, orderBy, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRouter, useRoute } from 'vue-router'
import PortalVisits from '@/components/PortalVisits.vue'

export default {
  name: 'PortalProperty',
  components: {
    PortalVisits
  },
  data() {
    return { 
      propertyDetail: {},
      detailPathName: null
    }
  },
  methods: {
    formatPropertyAddress(property) {
      if (!property.address) {
        return "n/a";
      }
      
      let street = property.address.street;
      
      if (property.address.street1) {
        street = property.address.street1;
      }
      
      if (property.address.street2) {
        street += " " + property.address.street2;
      }
    
      if (property.address.locality) {
        street += ", " + property.address.locality;
      }
      
      return street;
    },
    async init(propertyUid) {
      const db = getFirestore();
      const vm = this;
  
      const docRef = doc(db, "propertyListings", propertyUid);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        vm.propertyDetail = docSnap.data(); 
        vm.propertyDetail.id = docSnap.id;
      }
    }
  },
  props: ['propertyUid'],
  async created() {
    const vm = this;
    
    vm.detailPathName = 'PortalAgentsAgentProperty';
        
    let currentPathName = useRouter().currentRoute.value.name || '';
    if (currentPathName.indexOf('PortalProperties') === 0) {
      vm.detailPathName = 'PortalPropertiesProperty';
    }
    
    vm.init(vm.propertyUid);
  }
}
</script>