<template>
  <div class="admin-content-panel py-lg-15 d-flex flex-column-fluid align-items-start container-xxl">
    <div class="content flex-row-fluid" id="kt_content">
      
      <div class="card mb-5 mb-xl-8">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">
              <router-link class="btn btn-link fw-bolder btn-color-gray-900 fs-1" :to="{ name: 'PortalAgents' }">Agents</router-link>
            </span>
            <span class="text-muted mt-1 fw-bold fs-7">
              <span v-if="loadingData">Loading data...</span>
              <span v-if="!loadingData">{{agentsListProcessed.length}} Agents found</span>
            </span>
          </h3>
        </div>
        <div class="card-body py-3">
          <div class="table-responsive">
            <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 dataTable">
              <thead>
                <tr>
                  <td><input type="text" class="form-control" v-model.lazy="filter.name" placeholder="Agent Name..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.email" placeholder="Email Address..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.phoneNumber" placeholder="Phone..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.licenseNumber" placeholder="License Number..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.brokerageNameAndLicenseNumber" placeholder="Brokerage Name/License..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.brokeragePostalCode" placeholder="Brokerage ZIP..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.createdTimestampFormatted" placeholder="Created..." /></td>
                </tr>
                
                <tr class="fw-bolder text-muted">
                  <th @click="updateSortBy('name')" class="min-w-150px" :class="[sortBy.name === 'name' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'name' && !sortBy.asc && 'sorting sorting_desc']">Agent Name</th>
                  <th @click="updateSortBy('email')" class="min-w-140px" :class="[sortBy.name === 'email' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'email' && !sortBy.asc && 'sorting sorting_desc']">Email Address</th>
                  <th @click="updateSortBy('phoneNumber')" class="min-w-120px" :class="[sortBy.name === 'phoneNumber' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'phoneNumber' && !sortBy.asc && 'sorting sorting_desc']">Phone Number</th>
                  <th @click="updateSortBy('licenseNumber')" class="min-w-120px" :class="[sortBy.name === 'licenseNumber' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'licenseNumber' && !sortBy.asc && 'sorting sorting_desc']">License Number</th>
                  <th @click="updateSortBy('brokerageName')" class="min-w-120px" :class="[sortBy.name === 'brokerageName' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'brokerageName' && !sortBy.asc && 'sorting sorting_desc']">Brokerage</th>
                  <th @click="updateSortBy('brokeragePostalCode')" class="min-w-120px" :class="[sortBy.name === 'brokeragePostalCode' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'brokeragePostalCode' && !sortBy.asc && 'sorting sorting_desc']">Brokerage ZIP</th>
                  <th @click="updateSortBy('createdTimestampDate')" class="min-w-120px" :class="[sortBy.name === 'createdTimestampDate' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'createdTimestampDate' && !sortBy.asc && 'sorting sorting_desc']">Created</th>
                </tr>
                
              </thead>
              <tbody>
                <tr v-for="agent in agentsListProcessed" v-bind:key="agent.uid">
                  <td>
                    <router-link class="btn btn-link btn-color-info btn-active-color-primary fw-bolder text-hover-primary fs-6" :to="{ name: 'PortalAgentsAgent', params: { 'agentUid': agent.agentUid} }" append>{{agent.name}}</router-link>
                  </td>
                  <td>
                    <span class="text-dark fw-bolder">{{agent.email}}</span>
                  </td>
                  <td>
                    <span class="text-dark">{{agent.phoneNumber}}</span>
                  </td>
                  <td>
                    <span class="text-dark">{{agent.licenseNumber}}</span>
                  </td>
                  <td class="text-dark fw-bolder fs-6">
                    <span class="text-dark fw-bolder d-block mb-1 fs-6">{{agent.brokerageName}}</span>
                    <span class="text-muted fw-bold text-muted d-block fs-7">{{agent.brokerageLicenseNumber}}</span>
                  </td>
                  <td>
                    <span class="text-dark">{{agent.brokeragePostalCode}}</span>
                  </td>
                  <td>
                    <span class="text-dark">{{agent.createdTimestampFormatted}}</span>
                  </td>
                </tr>
                
                <tr v-if="loadingData">
                  <td  colspan="6" class="text-center">Loading data...</td>
                </tr>

                <tr v-if="!loadingData && agentsListProcessed.length === 0">
                  <td  colspan="6" class="text-center">No agents found...</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
    
  <form style="display: none;">
    <div>
      <label>
        <b>Name</b>
        
        <input type="text" v-model="newAgent.name" @keyup.enter="createAgent()" />
      </label>
    </div>
    
    <div>
      <label>
        <b>Email address</b>
        
        <input type="email" v-model="newAgent.email" @keyup.enter="createAgent()" />
      </label>
    </div>
    
    <div>
      <label>
        <b>Password</b>
        
        <input type="password" v-model="newAgent.password" @keyup.enter="createAgent()" />
      </label>
    </div>
    
    <div>
      <label>
        <b>Phone Number</b>
        
        <input type="phone" v-model="newAgent.phoneNumber" @keyup.enter="createAgent()" />
      </label>
    </div>
    
    <div>
      <label>
        <b>License Number</b>
        
        <input type="number" v-model="newAgent.licenseNumber" @keyup.enter="createAgent()" />
      </label>
    </div>
    
    <div>
      <label>
        <b>Brokerage Name</b>
        
        <input type="text" v-model="newAgent.brokerageName" @keyup.enter="createAgent()" />
      </label>
    </div>
    
    <div>
      <label>
        <b>Brokerage License Number</b>
        
        <input type="number" v-model="newAgent.brokerageLicenseNumber" @keyup.enter="createAgent()" />
      </label>
    </div>
    
    <div v-if="createAgentErrorMessage">
      <span><b>⚠️</b><br/>{{createAgentErrorMessage}}</span>
    </div>
    
    <div>
      <a @click="createAgent()">Create Agent</a>
    </div>
    
  </form>
  
  <router-view></router-view>
</template>

<script>
import { getAuth, signOut, signInAnonymously, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, orderBy, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'PortalAgents',
  data() {
    return { 
      loadingData: true,
      agentsList: [],
      agentsListProcessed: [],
      sortBy: { name: 'name', asc: false },
      filter: {},
      newAgent: {},
      createAgentErrorMessage: null
    }
  },
  watch: {
    filter: {
      handler(newFilters) {
        this.processAgentsList();
      },
      deep: true
    }
  },
  methods: {
    updateSortBy(name) {
      const vm = this;
      
      if (vm.sortBy.name === name) {
        vm.sortBy.asc = !vm.sortBy.asc;
      } else {
        vm.sortBy.name = name;
        vm.sortBy.asc = false;
      }
      
      vm.processAgentsList();
    },
    processAgentsList() {
      const vm = this;
      let processedList = [];
      
      // filter
      vm.agentsList.forEach(agent => {
        let passAllFilters = true;
        
        for (const [key, value] of Object.entries(vm.filter)) {
          if (!agent[key] || agent[key].toLowerCase().indexOf(value.toLowerCase()) === -1) {
            passAllFilters = false;
          }
        }
        
        if (passAllFilters) {
          processedList.push(agent);
        }
      });
      
      //sort
      processedList.sort((entity1, entity2) => {
        let property1 = entity1[vm.sortBy.name];
        let property2 = entity2[vm.sortBy.name];
        
        let result = 0;
        
        if (property1.getTime && property2.getTime) {
          result = property1.getTime() - property2.getTime();
        } else if (!isNaN(property1) && !isNaN(property2)) {
          result = property1 - property2;
        } else if (property1.localeCompare && property2.localeCompare) {
          result = property1.localeCompare(property2);
        }
        
        if (vm.sortBy.asc) {
          result *= -1;
        }
        
        return result;
      });
      
      vm.agentsListProcessed = processedList;
      vm.loadingData = false;
    },
    async createAgent() {
      const vm = this;
      const db = getFirestore();
      const auth = getAuth();
      
      vm.createAgentErrorMessage = null;
      
      if (!vm.newAgent.name || !vm.newAgent.email || !vm.newAgent.password || !vm.newAgent.phoneNumber || !vm.newAgent.licenseNumber || !vm.newAgent.brokerageName || !vm.newAgent.brokerageLicenseNumber) {
        vm.createAgentErrorMessage = 'All fields are required';
        return false;
      }
      
      const newUserResponse = await createUserWithEmailAndPassword(auth, vm.newAgent.email, vm.newAgent.password).catch((error) => {
        const errorMessage = error.message;
        
        console.log('Unable to create an account with provided email and password, ' + errorMessage);
      });
      
      if (!newUserResponse || !newUserResponse.user || !newUserResponse.user.uid) {
        vm.createAgentErrorMessage = 'Unable to create an account with provided email and password.';
        
        return false;
      }
      
      let newUserUid = newUserResponse.user.uid;
            
      await addDoc(collection(db, "agents"), {
        createdTimestamp: Timestamp.fromDate(new Date()),
        email: vm.newAgent.email,
        uid: newUserUid,
        phoneNumber: vm.newAgent.phoneNumber,
        name: vm.newAgent.name,
        licenseNumber: vm.newAgent.licenseNumber,
        brokerageName: vm.newAgent.brokerageName,
        brokerageLicenseNumber: vm.newAgent.brokerageLicenseNumber,
        _version: 0
      });
      
      alert(newUserUid);
    },
    async init() {
      const db = getFirestore();
      const vm = this;
  
      const docRefAgents = query(collection(db, "agents"), limit(1000));
      const docSnapAgents = await getDocs(docRefAgents);
      
      vm.agentsList = [];
      docSnapAgents.forEach(agent => {
        let agentData = agent.data();
        agentData.agentUid = agent.id;
        agentData.accountUid = agentData.uid;
        agentData.createdTimestampFormatted = agentData.createdTimestamp.toDate().toLocaleString();
        agentData.createdTimestampDate = agentData.createdTimestamp.toDate();
        agentData.brokerageNameAndLicenseNumber = agentData.brokerageName + agentData.brokerageLicenseNumber;
        
        vm.agentsList.push(agentData);
      });
      
      vm.processAgentsList();
    }
  },
  async created() {
    const vm = this;
    
    vm.init();
  }
}
</script>