import { createRouter, createWebHistory } from 'vue-router'
import PropertyListing from '../views/PropertyListing.vue'
import PortalLogin from '../views/PortalLogin.vue'
import PortalMain from '../views/PortalMain.vue'
import PortalAgents from '../views/PortalAgents.vue'
import PortalProperties from '../components/PortalProperties.vue'
import PortalAgent from '../views/PortalAgent.vue'
import PortalProperty from '../components/PortalProperty.vue'
import PortalVisit from '../components/PortalVisit.vue'
import PortalSettings from '../views/PortalSettings.vue'
import OAuthAuthorization from '../views/OAuthAuthorization.vue'

const routes = [
  {
    path: '/:propertyId?',
    name: 'PropertyListing',
    component: PropertyListing
  },
  {
    path: '/oauth/auth',
    name: 'OAuthAuthorization',
    component: OAuthAuthorization
  },
  {
    path: '/portal/login',
    name: 'PortalLogin',
    component: PortalLogin
  },
  {
    path: '/portal',
    name: 'PortalMain',
    component: PortalMain,
    children: [
      {
        path: 'agents/',
        name: 'PortalAgents',
        component: PortalAgents,
        children: [
          {
            path: ':agentUid',
            name: 'PortalAgentsAgent',
            props: true,
            component: PortalAgent,
            children: [
              {
                path: ':propertyUid',
                props: true,
                component: PortalProperty,
                name: 'PortalAgentsAgentProperty',
                children: [
                  {
                    path: ':visitUid',
                    props: true,
                    name: 'visitDetail',
                    name: 'PortalAgentsAgentPropertyVisit',
                    component: PortalVisit
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'properties',
        name: 'PortalProperties',
        component: PortalProperties,
        children: [
          {
            path: ':propertyUid',
            props: true,
            component: PortalProperty,
            name: 'PortalPropertiesProperty',
            children: [
              {
                path: ':visitUid',
                name: 'PortalPropertiesPropertyVisit',
                props: true,
                component: PortalVisit
              }
            ]
          }
        ]
      },
      {
        path: 'settings',
        name: 'PortalSettings',
        component: PortalSettings
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
